@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');



body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2F2F35;
  color: #FFFFFF;
}

a {
  font-family: "Montserrat"
}

a.link:link {
  color: #7290C9
}

a.link:visited {
  color: #7A1758;
}

a.link:hover {
  color: #1F4F9D;
}

a.link:active {
  color: blue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
